import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import styled from "styled-components"

const BlogPostListLayoutWrapper = styled.div`
  max-width: 100%;
  padding: 1rem 3rem;
  background-color: #f5f5f5;
`

const BlogPostItem = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 3rem 0 2rem 0;
`

const BlogPostItemOutter = styled.div`
  width: 778px;
  margin-block-end: 3rem;
`

const BlogPostItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: space-around;
    flex-direction: column;
  }
`

const BlogPostItemWrapperReversed = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: space-around;
    flex-direction: column-reverse;
  }
`

const BlogPostItemTitleWrapper = styled.div`
  position: relative;
  border-radius: 0 25px 0 0;
  text-align: left;
  background-color: #fff;
  padding: 1rem 2.1rem 2rem 2.1rem;
  border-bottom: 9px solid #eaeaea;

  @media (max-width: 768px) {
    border-radius: 0;
  }
`

const BlogPostItemTitleWrapperReversed = styled.div`
  position: relative;
  border-radius: 25px 0 0 0;
  text-align: left;
  background-color: #fff;
  padding: 1rem 2.1rem 2rem 2.1rem;
  border-bottom: 9px solid #eaeaea;

  @media (max-width: 768px) {
    border-radius: 0;
  }
`

const BlogPostItemTitle = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  color: #171717;
  font-weight: 300;
`

const BlogItemText = styled.div`
  text-align: left;
  font-size: 14px;
  color: #66686e;
  line-height: 25px;
  margin-block-end: 34px;
`

const BlogItemDate = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: #171717;
  font-weight: 600;
`

const BlogPostItemLeft = styled.div`
  flex: 2;
  margin: 8px;
`

const BlogPostItemRight = styled.div`
  flex: 1;
  margin: 0 0 0 18px;
`

const BlogPostItemLeftReversed = styled.div`
  flex: 1;
  margin: 8px;
`

const BlogPostItemRightReversed = styled.div`
  flex: 2;
  margin: 0 0 0 18px;
`

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PageNumberWrapper = styled.div`
  border: 1px solid #eee;
  background: ${props => (props.isCurrentPage ? "#eee" : "#fff")};
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }
`
const PortfolioImage = styled.img`
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  background-size: cover;
  overflow: hidden;
`

const ReadMoreLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: #171717;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.78rem;
  white-space: nowrap;
  border: 3px solid #171717;
  border-radius: 0.5rem;
  padding: 0.8rem 0.99rem 0.85rem;
  width: auto;

  &:hover {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#41aa0a),
      to(#0797b9)
    );
    background: linear-gradient(to right, #41aa0a, #0797b9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    transform: translate(50px, 80px);
    text-shadow: none;
    border-color: #0797b9;
  }
`

const PageNumber = styled(Link)`
  display: block;
  padding: 8px 16px;
`

class PostTemplate extends Component {
  render() {
    const { pageContext } = this.props

    return (
      <Layout>
        <BlogPostListLayoutWrapper>
          {pageContext.posts.map((post, index) =>
            index % 2 ? (
              <BlogPostItem key={post.node.wordpress_id}>
                <BlogPostItemOutter>
                  <BlogPostItemWrapperReversed>
                    <BlogPostItemLeftReversed>
                      <BlogItemText
                        dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                      />
                      <div>
                        <ReadMoreLink to={`/post/${post.node.slug}`}>
                          Read article
                        </ReadMoreLink>
                      </div>
                    </BlogPostItemLeftReversed>
                    <BlogPostItemRightReversed>
                      <BlogPostItemTitleWrapperReversed>
                        <BlogPostItemTitle
                          dangerouslySetInnerHTML={{ __html: post.node.title }}
                        />
                        <BlogItemDate>{post.node.date}</BlogItemDate>
                      </BlogPostItemTitleWrapperReversed>
                      <PortfolioImage
                        src={post.node.featured_image_src}
                        alt="Image"
                      />
                    </BlogPostItemRightReversed>
                  </BlogPostItemWrapperReversed>
                </BlogPostItemOutter>
              </BlogPostItem>
            ) : (
              <BlogPostItem key={post.node.wordpress_id}>
                <BlogPostItemOutter>
                  <BlogPostItemWrapper>
                    <BlogPostItemLeft>
                      <BlogPostItemTitleWrapper>
                        <BlogPostItemTitle
                          dangerouslySetInnerHTML={{ __html: post.node.title }}
                        />
                        <BlogItemDate>{post.node.date}</BlogItemDate>
                      </BlogPostItemTitleWrapper>
                      <PortfolioImage
                        src={post.node.featured_image_src}
                        alt="Image"
                      />
                    </BlogPostItemLeft>
                    <BlogPostItemRight>
                      <BlogItemText
                        dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                      />
                      <div>
                        <ReadMoreLink to={`/post/${post.node.slug}`}>
                          Read article
                        </ReadMoreLink>
                      </div>
                    </BlogPostItemRight>
                  </BlogPostItemWrapper>
                </BlogPostItemOutter>
              </BlogPostItem>
            )
          )}
          <Pagination>
            {Array.from({ length: pageContext.numberOfPages }).map(
              (page, index) => (
                <PageNumberWrapper
                  key={index}
                  isCurrentPage={index + 1 === pageContext.currentPage}
                >
                  <PageNumber to={index === 0 ? "/blog" : `/blog/${index + 1}`}>
                    {index + 1}
                  </PageNumber>
                </PageNumberWrapper>
              )
            )}
          </Pagination>
        </BlogPostListLayoutWrapper>
      </Layout>
    )
  }
}

export default PostTemplate
